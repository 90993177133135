<template>
  <v-container
    id="timeline"
    fluid
    tag="section"
  >
    <div class="contenedor">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="colorSnak"
      >
        {{ text }}
        <v-btn
          color="black"
          text
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </v-snackbar>
      <div
        v-if="bloqueado === true"
      >
        <div class="text_1">
          Has finalizado tu test
        </div>
      </div>
      <div
        v-if="bloqueado === false"
      >
        <div class="text_1">
          Instrucciones para realizar el test
        </div>
        <div>
          <v-card
            class="mx-auto"
            max-width="400"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/cards/1.png"
            />

            <v-card-subtitle class="pb-0">
              #1
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div style="text-align: center">
                En cada sección, debes marcar una opción de la columna "Más" y una opción de la columna "Menos".
              </div>
            </v-card-text>
          </v-card>
          <v-card
            class="mx-auto"
            max-width="400"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/cards/2.png"
            />

            <v-card-subtitle class="pb-0">
              #2
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div style="text-align: center">
                En la misma fila de una sección no se permite marcar la opción "Más" y "Menos".
              </div>
            </v-card-text>
          </v-card>
          <v-card
            class="mx-auto"
            max-width="400"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/cards/3.png"
            />

            <v-card-subtitle class="pb-0">
              #3
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div style="text-align: center">
                Debes contestar en todas las secciones para poder enviar tus resultados.
              </div>
            </v-card-text>
          </v-card>
          <v-card
            class="mx-auto"
            max-width="400"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/cards/4.png"
            />

            <v-card-subtitle class="pb-0">
              #4
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div style="text-align: center">
                ¡Pendiente del tiempo! Si se te acaba, tendrás que solictar permiso para poder repetir el test.
              </div>
            </v-card-text>
          </v-card>
          <v-card
            class="mx-auto"
            max-width="400"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/cards/5.png"
            />

            <v-card-subtitle class="pb-0">
              #5
            </v-card-subtitle>

            <v-card-text class="text--primary">
              <div style="text-align: center">
                Cuando hayas contestado todo, has clic en el botón para que sean enviadas tus respuestas.
              </div>
            </v-card-text>
          </v-card>
        </div>
        <br><br>
        <div class="text_2">
          Presiona el botón para comenzar
        </div>
        <div cols="12">
          <br>
          <div class="text-center">
            <v-row justify="center">
              <v-dialog
                v-model="dialog"
                :persistent="isPersistent"
                width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Iniciar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="titulo_modal">
                    <span class="headline">CONSENTIMIENTO EXPRESO PARA PRUEBAS PSICOTÉCNICAS para el <br> tratamiento de datos de carácter <br> personal.</span>
                    <img src="@/assets/logo.png">
                  </v-card-title>
                  <v-card-text>
                    <p>En cumplimiento de lo establecido en el Reglamento (UE) 679/2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de Datos de Carácter Personal y la Ley Orgánica 3/2018, de 5 de Diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales, le comunicamos que los datos que usted nos facilite quedarán incorporados y serán tratados por <b>RHONE ALPES SERVICIOS ESPAÑA ETT, SLU (en adelante <span style="color: #FE6000">RAS-INTERIM</span>),</b> con CIF <b>B62533518</b> y domicilio social en carrer Aragó, núm. 175-179, 1º 1ª; 08011, Barcelona (España); con el fin de poderle prestar los servicios solicitados.</p>
                    <p>En concreto los tratamientos para los que se solicita el consentimiento son los siguientes:</p>
                    <table style="width:100%">
                      <tr>
                        <td><b>Responsable</b></td>
                        <td><b>RHONE ALPES SERVICIOS ESPAÑA ETT, SLU – (<span style="color: #FE6000">RAS-INTERIM</span>)</b></td>
                      </tr>
                      <tr>
                        <td><b>DPD- DPO</b></td>
                        <td>Para cualquier consulta tiene a su disposición nuestro DPO a través de <a href="mailto:dpo@ras-interim.es">dpo@ras-interim.es</a></td>
                      </tr>
                      <tr>
                        <td><b>Tratamientos</b></td>
                        <td>Pruebas de valoración de aptitudes psicotécnicas individuales y /o colectivas realizadas por <span style="color: #FE6000"><b>RAS-INTERIM</b></span> o proveedor contratado para ello.</td>
                      </tr>
                      <tr>
                        <td><b>Categoría de datos a tratar</b></td>
                        <td>Datos relativos a inteligencia, percepción, memoria, aptitudes verbales, espaciales, de atención-concentración-retención, aptitud numérica, razonamiento abstracto, capacidades de organización y observación lógica, características de la personalidad en distintos aspectos de la vida, capacidades para la realización, comprensión y/o cualquier otra que permita evaluar desempeño de tareas.</td>
                      </tr>
                      <tr>
                        <td><b>Finalidad</b></td>
                        <td>Evaluación objetiva de las capacidades intelectuales y sociales del candidato ante las tareas que deba desempeñar en el puesto concreto al que se postula y poder precisar el perfil y potencial del candidato.</td>
                      </tr>
                      <tr>
                        <td><b>Legitimación</b></td>
                        <td>Consentimiento expreso, previo y por escrito del candidato, e interés legítimo empresarial.</td>
                      </tr>
                      <tr>
                        <td><b>Cesión a terceros</b></td>
                        <td>Los datos se tratarán de forma confidencial y no se transferirán a terceros, excepto a empresas del Grupo, o por imperativo legal y / o si esto es necesario para la ejecución por parte de un colaborador. Asimismo, cabe la posibilidad de ceder los resultados y/o conclusiones a la Empresa Cliente para la que se está postulando el candidato y que ésta puede decidir sobre la adecuación, o no, de la postulación.</td>
                      </tr>
                      <tr>
                        <td><b>Conservación</b></td>
                        <td>Un año desde la ejecución de las pruebas, salvo que exista algún otro plazo legal que obligue a que sea superior.</td>
                      </tr>
                      <tr>
                        <td><b>Transferencias Internacionales de Datos</b></td>
                        <td>No se prevé transferencia internacional de datos. Toda eventual comunicación se efectuará dentro del espacio de la Unión Europea y, en el supuesto de efectuarse algún fuera del mismo, se realizará conforme las estipulaciones legalmente establecidas.</td>
                      </tr>
                    </table>
                    <br>
                    <p>Mediante la aceptación del presente documento usted da su <b>consentimiento expreso, libre, informado y por escrito</b>, para que <span style="color: #FE6000"><b>RAS-INTERIM</b></span> pueda utilizar con las finalidades concretas descritas dichos datos facilitados por usted, comprometiéndose a tratar, sólo por personal autorizado y de forma confidencial, los datos de carácter personal facilitados, así como los resultados que se puedan derivar.</p>
                    <p>Asimismo, le informamos de la posibilidad que tiene de ejercitar en todo momento sus derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación en el tratamiento, dirigiéndose a <span style="color: #FE6000"><b>RAS-INTERIM</b></span>, mediante las correspondientes solicitudes que se hallan a su disposición y que nos puede solicitar en el mail <a href="mailto:dpo@ras-interim.es">dpo@ras-interim.es</a>. Asimismo, puede presentar reclamación ante la Autoridad de Control (AEPD) si considera que sus derechos han sido vulnerados.</p>
                    <p>
                      Puede consultar la información adicional y detallada sobre Protección de Datos en nuestra página web:
                      <a
                        href="https://www.ras-interim.es/politica-de-privacidad/"
                        target="_blank"
                      >
                        https://www.ras-interim.es/politica-de-privacidad/
                      </a>
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog = false"
                    >
                      Cerrar
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog = false; goToTest()"
                    >
                      Acepto
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  import UsuariosApi from '@/services/api/Usuarios'

  export default {
    data () {
      return {
        bloqueado: null,
        colorSnak: 'info',
        snackbar: false,
        text: '',
        timeout: 3000,
        dialog: false,
        isPersistent: true,
      }
    },
    created () {
      UsuariosApi.getEstadoBloqueo()
        .then((response) => {
          if (response.res === true) {
            localStorage.setItem('bloqueado', 'bloqueado')
          } else {
            localStorage.setItem('bloqueado', 'null')
          }
          if (localStorage.getItem('bloqueado') === 'null') {
            this.bloqueado = false
          } else {
            this.bloqueado = true
          }
        })
        .catch((error) => {
          console.log(error)
          this.text = 'No ha sido posible obtener los datos'
          this.colorSnak = 'error'
          this.snackbar = true
          if (localStorage.getItem('bloqueado') === 'null') {
            this.bloqueado = false
          } else {
            this.bloqueado = true
          }
        })
    },
    methods: {
      goToTest () {
        localStorage.setItem('fecha_consentimiento', new Date().toISOString())
        this.$router.push({ path: 'test' })
      },
    },
  }
</script>

<style scoped lang="scss">
.contenedor {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
}

.text_1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  position: relative;
  z-index: 1;
}

.text_2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  position: relative;
  z-index: 1;
}

.titulo_modal {
  display: flex !important;
  flex-flow: nowrap;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

th, td {
  padding: 5px;
}

a { color: #2b73f8; }
</style>
